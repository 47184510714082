<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="编辑招聘信息"/>
		<RecruitmentForm :newsForm="form" @eventForm="handleGetForm"class="purForm"  />
	</div>
</template>

<script>
	import RecruitmentForm from '@/components/Recruitment/Form'
	export default {
		data() {
			return {
				form: {}
			}
		},
		created() {
			this.$axios.post('/getnews', {
				id: this.$route.query.id
			}).then(data => {
				this.form = data
			})
		},
		methods: {
			handleGetForm(form) {
				this.$axios.post('/addnews', form).then(data => {
					this.$message.success('修改成功')
					this.$router.push('/recruitment')
				})
			}
		},
		components: {
			RecruitmentForm
		}
	}
</script>
